/* Loading.css */
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.loader {
  position: relative;
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey with transparency */
  border-top: 4px solid #4fa370; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite, glow 2s ease-in-out infinite alternate;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes glow {
  0% { box-shadow: 0 0 8px #4ecc82; }
  100% { box-shadow: 0 0 20px #06532f; }
}
